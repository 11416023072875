import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AddCardIcon from "@mui/icons-material/AddCard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  ListItem,
  ListItemButton,
  Typography,
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { Pages } from "../../utils/pages";
import logo from "../../images/open.svg";
import { setAccessToken, setRefreshAccessToken } from "../../utils/tokenStore";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { endsWithNumber } from "../../utils/math";

const drawerWidth = 200;

const SideBar: React.FC<{}> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [beneNavMenuOpen, setBeneNavMenuOpen] = React.useState(true);

  const handleClick = () => {
    setBeneNavMenuOpen(!beneNavMenuOpen);
  };

  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <div className="home">
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <Box
              component="img"
              display={"block"}
              sx={{
                margin: "10pt",
              }}
              alt="OpenCloset"
              src={logo}
            />
            <Divider />
            <ListItem key={1} disablePadding>
              <ListItemButton>
                <Typography fontWeight={"bold"} marginTop={"2pt"}>
                  Principale
                </Typography>
              </ListItemButton>
            </ListItem>
            <List>
              <ListItem
                key={140}
                disablePadding
                onClick={() => navigate(Pages.HOME)}
              >
                <ListItemButton selected={Pages.HOME === pathname}>
                  <ListItemIcon>
                    <DashboardIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Dashboard"} />
                </ListItemButton>
              </ListItem>
              {/* TODO: change with real page */}
              <ListItem
                key={141}
                disablePadding
                onClick={() => navigate(Pages.HOME)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MeetingRoomIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Appuntamenti"} />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <ListItem key={2} disablePadding>
              <ListItemButton>
                <Typography fontWeight={"bold"} marginTop={"2pt"}>
                  Gestione
                </Typography>
              </ListItemButton>
            </ListItem>
            <List>
              <ListItem
                key={14}
                disablePadding
                onClick={() => navigate(Pages.PERSONA_FISICA)}
              >
                <ListItemButton
                  selected={pathname.includes(Pages.PERSONA_FISICA)}
                >
                  <ListItemIcon>
                    <GroupsIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Cliente"} />
                </ListItemButton>
              </ListItem>
              <ListItem
                key={15}
                disablePadding
                onClick={() => navigate(Pages.CONTRATTO)}
              >
                <ListItemButton selected={pathname.includes(Pages.CONTRATTO)}>
                  <ListItemIcon>
                    <AddCardIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Contratti"} />
                </ListItemButton>
              </ListItem>

              <ListItemButton
                key={16}
                onClick={() => {
                  navigate(Pages.BENE);
                  handleClick();
                }}
                selected={
                  pathname.endsWith(Pages.BENE) ||
                  (pathname.includes(Pages.BENE) && endsWithNumber(pathname))
                }
              >
                <ListItemIcon>
                  <ShoppingBasketIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary={"Beni"} />
                {beneNavMenuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={beneNavMenuOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    onClick={() => navigate(Pages.BENE_COLLEZIONE)}
                    sx={{ pl: 4 }}
                    selected={pathname.includes(Pages.BENE_COLLEZIONE)}
                  >
                    <ListItemText primary={"Collezioni"} />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => navigate(Pages.BENE_BRAND)}
                    sx={{ pl: 4 }}
                    selected={pathname.includes(Pages.BENE_BRAND)}
                  >
                    {/* <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon> */}
                    <ListItemText primary={"Brand Bene"} />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => navigate(Pages.BENE_MATERIALE)}
                    sx={{ pl: 4 }}
                    selected={pathname.includes(Pages.BENE_MATERIALE)}
                  >
                    <ListItemText primary={"Materiali"} />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => navigate(Pages.BENE_QUALITA)}
                    sx={{ pl: 4 }}
                    selected={pathname.includes(Pages.BENE_QUALITA)}
                  >
                    <ListItemText primary={"Condizioni Bene"} />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => navigate(Pages.BENE_TIPOLOGIA)}
                    sx={{ pl: 4 }}
                    selected={pathname.includes(Pages.BENE_TIPOLOGIA)}
                  >
                    <ListItemText primary={"Tipologie"} />
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => navigate(Pages.BENE_COLORE)}
                    sx={{ pl: 4 }}
                    selected={pathname.includes(Pages.BENE_COLORE)}
                  >
                    <ListItemText primary={"Colori"} />
                  </ListItemButton>
                </List>
              </Collapse>

              {/* <ListItem key={16} disablePadding onClick={() => navigate(Pages.BENE)}>
                <ListItemButton selected={pathname.includes(Pages.BENE)}>
                  <ListItemIcon>
                    <ShoppingBasketIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Beni"} />
                </ListItemButton>
              </ListItem> */}
            </List>
            <Divider />
            <ListItem key={3} disablePadding>
              <ListItemButton>
                <Typography fontWeight={"bold"} marginTop={"2pt"}>
                  Servizi
                </Typography>
              </ListItemButton>
            </ListItem>
            <List>
              <ListItem
                key={4}
                disablePadding
                onClick={() => {
                  setAccessToken("");
                  setRefreshAccessToken("");
                  navigate(Pages.ROOT);
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ExitToAppIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary={"Disconnetti"} />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
        </div>
      </Box>
    </>
  );
};

export default SideBar;
