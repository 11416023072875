import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  WithCUToggleCommandsAndData,
  WithRecordID,
  WithData,
} from "../../../models/shared/CommonModels";
import {
  Indirizzo,
  IndirizzoPostModel,
  IndirizzoPutModel,
  IndirizzoRequest,
} from "../../../models/IndirizzoModel";
import {
  fetchIndirizziQueryKey,
  fetchSingleIndirizzoQueryKey,
} from "../../../utils/QueryClient";
import {
  createIndirizzo,
  editIndirizzo,
  fetchIndirizzo,
} from "../../../api/indirizzi";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { REQUIRED_HELPER_TEXT } from "../../../utils/form";
import { AxiosError } from "axios";
import { fromAxiosErrorToMessage } from "../../../utils/crud";
import ErrorMessageAlertComponent from "../../errors/ErrorMessageAlertComponent";

const CustomerIndirizzoForm: React.FC<
  WithCUToggleCommandsAndData<Indirizzo>
> = ({ initialData, open, toggleOpen, editEnabled, onClose }) => {
  const queryClient = useQueryClient();

  const [hideError, setHideError] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [indirizzo, setIndirizzo] = useState<string>();
  const [nazione, setNazione] = useState<string>();

  const query = useQuery({
    queryKey: [fetchSingleIndirizzoQueryKey, initialData.id],
    queryFn: () => fetchIndirizzo(initialData.id),
    enabled: !!initialData.id,
    onSuccess: (getResponse) => {
      setIndirizzo(getResponse.data.indirizzo);
      setNazione(getResponse.data.nazione);
    },
    refetchOnWindowFocus: false,
  });

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createIndirizzo(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchIndirizziQueryKey);
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editIndirizzo(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchIndirizziQueryKey);
    },
    onError: (err: AxiosError) => {
      setHideError(false);
      setErrorMessage(fromAxiosErrorToMessage(err));
    },
  });

  const resetFields = () => {
    // resetting fields
    setIndirizzo("");
    setNazione("");
  };

  if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  const closeActions = () => {
    toggleOpen();
    if (!hideError) {
      queryClient.invalidateQueries(fetchIndirizziQueryKey);
    }
  };

  const handleSubmit = () => {
    if (indirizzo === undefined || nazione === undefined) {
      return;
    }

    if (editEnabled) {
      const indirizzoTemplate = {} as IndirizzoPutModel;
      const indirizzoData = {} as IndirizzoRequest;
      const postData = {} as WithRecordID;
      indirizzoTemplate.nazione = nazione;
      indirizzoTemplate.indirizzo = indirizzo;
      indirizzoData.indirizzocliente = indirizzoTemplate;
      postData.data = indirizzoData;
      postData.recordID = initialData.id;

      editMutation.mutate(postData);
    } else {
      const indirizzoPost = {} as IndirizzoPostModel;
      indirizzoPost.id_cliente = initialData.id_cliente;
      indirizzoPost.nazione = nazione;
      indirizzoPost.indirizzo = indirizzo;
      const postData = {} as WithData;
      const body = {} as IndirizzoRequest;
      body.indirizzocliente = indirizzoPost;
      postData.data = body;

      createMutation.mutate(postData);
    }

    resetFields();
    toggleOpen();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>
          {editEnabled ? "Modifica" : "Crea Nuovo"} Indirizzo Cliente
        </DialogTitle>
        <DialogContent style={{ width: 500 }}>
          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => {
              setHideError(true);
            }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>
            {REQUIRED_HELPER_TEXT}
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Indirizzo"
            name="indirizzo"
            size="small"
            value={indirizzo}
            onChange={(e) => setIndirizzo(e.target.value)}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            label="Nazione"
            name="nazione"
            size="small"
            value={nazione}
            onChange={(e) => setNazione(e.target.value)}
          />
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "18pt",
            paddingRight: "18pt",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button
              onClick={() => {
                if (onClose !== undefined) {
                  onClose();
                }

                if (!editEnabled) {
                  resetFields();
                }
                closeActions();
              }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">
              {editEnabled ? "Salva" : "Aggiungi"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomerIndirizzoForm;
