import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { ContractDocumentModel } from "../../models/ContrattoModel";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import base64ToArrayBuffer from "../../utils/bytes";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { WithToggleAndReadonlyAndData } from "../../models/shared/CommonModels";

const ContractDocumentPreview: React.FC<
  WithToggleAndReadonlyAndData<ContractDocumentModel>
> = ({
  initialData,
  open,
  toggleOpen,
  readonly,
  handleSubmit,
  handleClose,
}) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [completedLoading, setCompletedLoading] = useState<boolean>(false);

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/legacy/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const pdfDocument = base64ToArrayBuffer(initialData.base64ContractPdf);

  const handleClosing = () => {
    setPageNumber(1);
    if (handleClose !== undefined) handleClose();
    toggleOpen();
  };

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
        maxWidth="md"
      >
        <DialogTitle>
          Anteprima Contratto
          {numPages > 1 && (
            <>
              : {pageNumber} / {numPages}
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Document
            file={pdfDocument}
            onLoadSuccess={(pdfInfo) => {
              setNumPages(pdfInfo.numPages);
              setCompletedLoading(true);
            }}
            loading={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            }
            error={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            }
          >
            <Page
              pageNumber={pageNumber}
              className={"contractDocumentPage"}
              renderAnnotationLayer={false}
              renderTextLayer={true}
              loading={
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              }
            />
          </Document>
        </DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "18pt",
            paddingRight: "18pt",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button onClick={handleClosing}>Chiudi</Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            {numPages > 1 && (
              <>
                {pageNumber !== 1 ? (
                  <Button
                    sx={{ marginLeft: "5pt" }}
                    onClick={() => goToPrevPage()}
                    variant="contained"
                    color="secondary"
                  >
                    {"<"}
                  </Button>
                ) : (
                  <></>
                )}
                {pageNumber < numPages ? (
                  <Button
                    sx={{ marginLeft: "5pt" }}
                    onClick={() => goToNextPage()}
                    variant="contained"
                    color="secondary"
                  >
                    {">"}
                  </Button>
                ) : (
                  <></>
                )}
              </>
            )}
            {completedLoading && !readonly ? (
              <Button
                sx={{ marginLeft: "5pt" }}
                onClick={() => {
                  toggleOpen();
                  if (handleSubmit !== undefined) handleSubmit();
                }}
                variant="contained"
              >
                Conferma
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContractDocumentPreview;
