import {
  Grid,
  Box,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@mui/material";
import { useState } from "react";
import { ContrattoDetailed } from "../../models/ContrattoModel";
import EditIcon from "@mui/icons-material/Edit";
import { displayFormatted } from "../../utils/date";
import DisplayField from "../shared/DisplayField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContrattoFormComponent from "./ContrattoFormComponent";
import ContractStateHistory from "./ContractStateHistory";
import SectionTitle from "../shared/SectionTitle";

interface WithContratto {
  contratto: ContrattoDetailed;
}

const InfoContrattoComponent: React.FC<WithContratto> = ({ contratto }) => {
  // const { token } = useContext(apiContext)
  // const queryClient = useQueryClient()

  const [mainDataEditFormOpen, setMainDataEditFormOpen] =
    useState<boolean>(false);
  const toggleMainDataEditFormOpen = () =>
    setMainDataEditFormOpen(!mainDataEditFormOpen);

  // TODO: verify if (statoContrattoQuery.isError) return (<Navigate to="/contratto" />)

  return (
    <>
      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Contratto - Dati Principali" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {/* <DisplayField title="Quantità" text={contratto.quantita + ""} />
              <DisplayField title="Importo" text={contratto.importo + " €"} /> */}
              <DisplayField
                title="Stato"
                text={contratto.statocontratto.nome}
              />
              <DisplayField
                title="Tipologia"
                text={contratto.tipologiacontratto.nome}
              />
              <DisplayField
                title="Cliente Associato"
                text={
                  contratto.anagraficacliente.personafisica?.nome +
                  (" " + contratto.anagraficacliente.personafisica?.cognome)
                }
              />
              <DisplayField
                title="Data di Scadenza"
                text={displayFormatted(contratto.data_scadenza)}
              />
            </Grid>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <DisplayField
                fill={10}
                title="Note"
                text={contratto.note || "Non ci sono note"}
              />
            </Grid>
          </AccordionDetails>
          <AccordionActions>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => {
                setMainDataEditFormOpen(true);
              }}
            >
              Modifica
            </Button>
          </AccordionActions>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Contratto - Storico Stati" />
          </AccordionSummary>
          <AccordionDetails>
            <Box display={"flex"} justifyContent={"center"}>
              <ContractStateHistory history={contratto.storicostatocontratto} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Edit */}
      <ContrattoFormComponent
        initialData={contratto}
        open={mainDataEditFormOpen}
        toggleOpen={toggleMainDataEditFormOpen}
        editEnabled={true}
      />
    </>
  );
};

export default InfoContrattoComponent;
