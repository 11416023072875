import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Skeleton } from "@mui/material";
import "./CustomerDetails.scss";
import {
  WithData,
  WithAnagraficaClienteID,
} from "../../models/shared/CommonModels";
import { Indirizzo } from "../../models/IndirizzoModel";
import { deleteIndirizzo, fetchCustomerIndirizzi } from "../../api/indirizzi";
import queryClient, { fetchIndirizziQueryKey } from "../../utils/QueryClient";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CustomerIndirizzoForm from "./extra/CustomerIndirizzoForm";
import DeleteDialog from "../shared/DeleteDialog";
import SimpleDataTable from "../shared/SimpleDataTable";

interface WithIndirizzoRecord extends WithAnagraficaClienteID {
  record: Indirizzo;
  createFormOpen: boolean;
  toggleCreateFormOpen: () => void;
}

const CustomerIndirizziTable: React.FC<WithIndirizzoRecord> = ({
  anagrafica_cliente_id,
  createFormOpen,
  toggleCreateFormOpen,
}) => {
  const [indirizzi, setIndirizzi] = useState<Indirizzo[]>(undefined as any);
  const [selectedRowElement, setSelectedRowElement] = useState<Indirizzo>();

  const [indirizzoDataEditFormOpen, setIndirizzoDataEditFormOpen] =
    useState<boolean>(false);
  const toggleIndirizzoDataEditFormOpen = () =>
    setIndirizzoDataEditFormOpen(!indirizzoDataEditFormOpen);

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen);

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteIndirizzo(x),
    onSuccess: () => {
      setSelectedRowElement(undefined);
      queryClient.invalidateQueries(fetchIndirizziQueryKey);
    },
    onError: () => {},
  });

  const query = useQuery({
    queryKey: [fetchIndirizziQueryKey],
    queryFn: () => fetchCustomerIndirizzi(anagrafica_cliente_id),
    onSuccess: (getResponse) => {
      setIndirizzi(getResponse.data);
    },
    refetchOnWindowFocus: false,
  });

  const editOperations = (record: GridRenderCellParams<any, Indirizzo>) => {
    setSelectedRowElement(record.value);
    toggleIndirizzoDataEditFormOpen();
  };

  const deleteOperations = (record: GridRenderCellParams<any, Indirizzo>) => {
    setSelectedRowElement(record.value);
    toggleDeleteAlert();
  };

  // TODO: verify if (query.isError) return (<Navigate to="/home" replace />)

  if (query.isFetching || query.isLoading) return <Skeleton animation="wave" />;

  return (
    <>
      <SimpleDataTable<Indirizzo>
        columns={[
          {
            field: "indirizzo",
            headerName: "Indirizzo",
            flex: 1,
          },
          {
            field: "nazione",
            headerName: "Nazione",
            flex: 1,
          },
        ]}
        rows={
          indirizzi !== undefined
            ? indirizzi.map((d) => {
                return {
                  id: d.id,
                  indirizzo: d.indirizzo,
                  nazione: d.nazione,
                  actions: d,
                  delete_action: d,
                } as unknown as Indirizzo;
              })
            : []
        }
        rowCount={indirizzi.length}
        editOperations={editOperations}
        deleteOperations={deleteOperations}
      />

      {/* Add Indirizzo */}
      <CustomerIndirizzoForm
        initialData={{ id_cliente: anagrafica_cliente_id } as Indirizzo}
        open={createFormOpen}
        toggleOpen={toggleCreateFormOpen}
      />

      {/* Edit Indirizzo */}
      {selectedRowElement && (
        <CustomerIndirizzoForm
          initialData={selectedRowElement}
          open={indirizzoDataEditFormOpen}
          toggleOpen={toggleIndirizzoDataEditFormOpen}
          editEnabled={true}
        />
      )}

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.indirizzo}
        onPositive={() => {
          toggleDeleteAlert();
          const x = {} as WithData;
          x.data = selectedRowElement?.id;
          deleteMutation.mutate(x);
        }}
        onNegative={toggleDeleteAlert}
      />
    </>
  );
};

export default CustomerIndirizziTable;
